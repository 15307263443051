import React from "react";
import PropTypes from "prop-types";


class BlogContent extends React.Component {

  renderHeader() {
    const { blog } = this.props;
    return (
      <div className="col-xs-12 portfolio-blog-header">
        {blog.content_type ? (
          <a href={`/blog/category/${blog.content_type}`}className="portfolio-blog-header-content-type">
            {blog.content_type}
          </a>
        ):""}
        <div className="portfolio-blog-header-title">
          {blog.title}
        </div>
        <div className="portfolio-blog-header-attribution">
          By Jory Leech
        </div>
      </div>
    )
  }

  renderHeaderImage() {
    const { blog } = this.props;
    return (
      <div className="col-xs-12">
        <img className="portfolio-blog-hero-image" src={blog.header_image || "/images/blog/missing_hero_image.png"}/>
      </div>
    )
  }

  renderBody() {
    const { blog } = this.props;
    return (
      <div className="col-xs-12">
        <div dangerouslySetInnerHTML={{__html: blog.body}} />
      </div>
    )
  }

  renderTags() {

  }

  render() {
    return (
      <div className="portfolio-white-subsection">
        <div className="container ">
          <div className="row">
            {this.renderHeader()}
            {this.renderHeaderImage()}
            {this.renderBody()}
            {this.renderTags()}
          </div>
        </div>
      </div>
    )

  }
}

export default BlogContent
