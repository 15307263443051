import React from "react";
import PropTypes from "prop-types";

class IndexProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  upcomingProjectsList() {
    return [
      {
        title: "Projects Reports Blog",
        description: "A place to store informative writeups about projects and their solutions.",
        date:"Upcoming"
      },
    ]
  }

  finishedProjectsList() {
    return [
      {
        title: "Global Game Jam '21",
        description: "An annual competition to build a game in 3 days. I have succeeded for 2 years in a row. Here's hoping for another.",
        date:"Jan 29th - Jan 31st "
      },
      {
        title: "Dedicated Misc Heroku/Rails Webserver",
        description: "A personal heroku rails server with postgresql database for upcoming REST projects.",
        date:"Jan 22nd - Jan 23rd"
      },
      {
        title: "Tabletop Setting Omnibus",
        description: "A hardcover containing 6 years worth of tabletop settins, with a dedication to the friends I made along the way.",
        date:"Finished November 2020"
      },
    ]
  }

  renderList(name, items) {
    const listItems = items.map(item => this.renderListItem(item));
    return (
      <div className="col-xs-12 col-md-6 portfolo-index-projects-container">
        <div className="portfolo-index-projects-header">
          {name}
        </div>
        {listItems}
      </div>
    )
  }

  renderListItem(item) {
    return (
      <div className="portfolio-index-project-item">
        <div className="portfolio-index-project-name">
          {item.title}
        </div>
        <div className="portfolio-index-project-date">
          {item.date}
        </div>
        <div className="portfolio-index-project-description">
          {item.description}
        </div>
      </div>
    )
  }

  render() {
    console.log('test');
    return (
      <div className="row">
        {this.renderList("Upcoming Projects", this.upcomingProjectsList())}
        {this.renderList("Recent Projects", this.finishedProjectsList())}
      </div>
    )
  }
}

export default IndexProjectList
