import React from "react";
import PropTypes from "prop-types";

class IndexProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    }
    this.toggleOpen = this.toggleOpen.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.closeMenu);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.closeMenu);
  }

  closeMenu() {
    this.setState({
      menuOpen: false
    })
  }

  getOptions() {
    return [
      {text: "Dashboard", type:"link", url:"/"},
      {text: "Sign Out", type:"link", url:"/users/sign_out"}
    ]
  }

  renderOpenMenu() {
    const { menuOpen } = this.state;
    if(menuOpen) {
      const renderMenuItems = this.getOptions().map(x=> this.renderMenuItem(x))
      return (
        <div className="portfolio-header-menu-outer">
          <div className="portfolio-header-menu-inner">
            {renderMenuItems}
          </div>
        </div>
      )
    }
  }

  renderMenuItem(item) {
    switch(item.type) {
      case "link":
        return <a className="portfolio-header-menu-item" href={item.url}>{item.text}</a>
    }
  }

  toggleOpen() {
    const { menuOpen } = this.state;
    this.setState({
      menuOpen: !menuOpen
    })
  }

  render() {
    return (
      <div className="portfolio-header-menu" onClick={(e)=>e.stopPropagation()}>
        <div onClick={this.toggleOpen} className="portfolio-header-menu-icon">
          Menu
        </div>
        {this.renderOpenMenu()}
      </div>
    )
  }
}

export default IndexProjectList
