import React from "react";
import PropTypes from "prop-types";
import Select from "react-select"
import { getIn } from "formik";

class FormikSelect extends React.Component {

  render() {
    const { formikProps, name, options } = this.props;
    const currentValue = (options||[]).find(x=>x.value == getIn(formikProps.values, name)) || {value: "", label: ""}
    return (
      <Select
        {...this.props}
        onChange={(value)=> {formikProps.setFieldValue(name, value.value)}}
        classNamePrefix="formik-select"
        className="formik-select-container"
        value={currentValue}

      />
    )
  }
}

export default FormikSelect
