import React from "react";
import PropTypes from "prop-types";
import { Editor } from '@tinymce/tinymce-react';
import { Form, Field, Formik } from "formik";
import Cookies from 'universal-cookie';
import FormikSelect from "../forms/FormikSelect"
import BlogContent from "./show/BlogContent"


class BlogForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: false,
    }
    this.bodyEditorRef = React.createRef
  }

  getBlogValues(formikProps) {
    return formikProps.values.blog;
  }

  renderBody(formikProps) {
    const blog = this.getBlogValues(formikProps);
    console.log(this.bodyEditorRef.current ? this.bodyEditorRef.current.getContent():"");
    return (
      <Editor
       onInit={(evt, editor) => this.bodyEditorRef.current = editor}
       apiKey="0cq9yxdhx93gygo567tydapqtz9n170ymurnb4d64oluf9j3"
       initialValue={blog.body}
       init={{
         height: 500,
         menubar: false,
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',

           'insertdatetime media table paste code help wordcount'
         ],
         toolbar: 'undo redo | formatselect | ' +
         'bold italic backcolor | alignleft aligncenter ' +
         'alignright alignjustify | bullist numlist outdent indent | ' +
         'removeformat | help',
         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
       }}
     />
    )
  }

  getContentTypes() {
    return [
      "Games",
      "Books",
      "Project Report"
    ].map(x=>{return {value: x, label: x}})
  }

  getStatusTypes() {
    return [
      "draft",
      "published",
      "archived"
    ].map(x=>{return {value: x, label: x}})
  }

  togglePreview(e, formikProps) {
    formikProps.setFieldValue("blog[body]", this.bodyEditorRef.current.getContent())
    this.setState({
      preview: e.target.checked
    })
  }

  renderPreviewOption(formikProps) {
    const { preview } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-right">
            Preview?
            <input type="checkbox" checked={preview} onChange={(e)=>this.togglePreview(e,formikProps)} />
          </div>
        </div>
      </div>
    )
  }

  renderPreview(formikProps) {
    const { preview } = this.state;
    if(preview) {
      return (
        <BlogContent
          blog={formikProps.values.blog}
        />
      )
    }

  }


  renderForm() {
    const { blog } = this.props
    const { preview } = this.state;
    return (
      <Formik
        initialValues = {{
          blog: {
            body: blog.body || "",
            status: blog.status,
            title: blog.title,
            content_type: blog.content_type,
            start_date: blog.start_date,
            end_date: blog.end_date
          }
        }}
        onSubmit={(valuesTemp) => {
          let values = valuesTemp
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          if(values.blog.status == "uncreated") {
            values.blog.status = "draft";
          }
          if(this.bodyEditorRef.current) {
            values.blog.body = this.bodyEditorRef.current.getContent()
          }
          fetch(`/blog/${blog.id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(values),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': token
            },
          }).then(response => response.json()).then(json=> {
            if(!json.error) {
              window.location = `/blog/${blog.id}/edit`
            }
            else {
              this.setState({
                error: json.error
              })
            }

          })
        }}
      >
      {(formikProps) => (
        <Form>
          {this.renderPreviewOption(formikProps)}
          {this.renderPreview(formikProps)}
        <div className="container">
          <div className="row" style={{visibility: preview ? "hidden" : "visible", height: preview ? 0 : "auto", overflow: "hidden"}}>
            <div className="col-xs-12">
               <label>Title:</label>
               <Field name="blog[title]" placeholder="Title" className="form-control"/>
            </div>
            <div className="col-xs-12">
              <label> Type: </label>
              <FormikSelect
                formikProps={formikProps}
                options={this.getContentTypes()}ype
                name="blog[content_type]"
              />
            </div>

            <div className="col-xs-12 col-md-6">
              <label>Start Date:</label>
              <Field name="blog[start_date]" type="date" className="form-control"/>
            </div>
            <div className="col-xs-12 col-md-6">
              <label>End Date:</label>
              <Field name="blog[end_date]" type="date" className="form-control"/>
            </div>
            <div className="col-xs-12">
              <label> Body: </label>
              {this.renderBody(formikProps)}
            </div>
            <div className="col-xs-12">
              <input id="file" name="file" type="file" onChange={(event) => {
                formikProps.setFieldValue("file", event.currentTarget.files[0]);
              }} />
            </div>
            <div className="col-xs-12 col-md-6">
              <label> Status: </label>
              <FormikSelect
                formikProps={formikProps}
                options={this.getStatusTypes()}
                name="blog[status]"
              />
            </div>

            <div className="col-xs-12">
              <br/>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
              <button type="button" onClick={()=> window.location="/blog"} className="btn btn-secondary">
                Cancel
              </button>
            </div>
            <div className="col-xs-12">
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
          </div>
        </div>
        </Form>
      )}
      </Formik>
    )
  }

  render() {
    return this.renderForm();
  }
}

export default BlogForm
